import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Workouts.module.sass";
import Image from "../Image";

const items = [
  "Customised Workout Routine",
  "Tailored Meal Plan",
  "Workout Anywhere Anytime",
  "Track your progress easily",
  "24X7 Support",
];

const Workouts = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            
          <Image
              srcSet="/images/content/gaurabhparallex3.webp 2x"
              srcSetDark="/images/content/gaurabhparallex3.webp 2x"
              src="/images/content/gaurabhparallex3.webp"
              srcDark="/images/content/gaurabhparallex3.webp"
              alt="Gaurabh"
            />
        </div>
        
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>
            All your workouts, <br></br>in your pocket
          </h2>
          <div className={styles.info}>
            Fitness Pro helps track your workouts, get better results, and be
            the best version of you. Less thinking!
          </div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li className={styles.item} key={index}>
                {x}
              </li>
            ))}
          </ul>
          <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="/pricing">
              Choose Plan
            </Link>
            <button className={cn("button-stroke", styles.button)}>
              Try Before Buy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workouts;
