import React from "react";
import Hero from "./Hero";
import Steps from "./Steps";
import Team from "./Team";
import Workouts from "../../components/Workouts";
import Offer from "../../components/Offer";

const Home = () => {
  return (
    <>
      <Hero />
      <Steps />
      <Workouts />
      <Team />
      <Offer />
    </>
  );
};

export default Home;
