import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";

const Hero = () => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            Train smarter. get stronger
          </div>
          <h1 className={cn("h1", styles.title)}>
           Fitness Is Now Simplified For Everyone.
          </h1>
          <div className={styles.text}>
            Understand, Learn And Then Follow What We Say:
            Workout/Meal/Supplementation To get better results, and be the best version of
            you. Less thinking, more lifting.
          </div>
          <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="/download">
              Know More
            </Link>
            <Link className={cn("button-stroke", styles.button)} to="/class02-details">
              Book Now
            </Link>
          </div>
        </ScrollParallax>
    
        <div className={styles.gallery}>
        <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <Image
              srcSet="/images/content/transformation-1.webp 2x"
              srcSetDark="/images/content/transformation-1.webp 2x"
              src="/images/content/transformation-1.webp"
              srcDark="/images/content/transformation-1.webp"
              alt="sythol"
            />
          </ScrollParallax>  
        </div>
      </div>
    </div>
  );
};

export default Hero;
