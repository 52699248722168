import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Offer.module.sass";
import ScrollParallax from "../../components/ScrollParallax";

const Offer = () => {
  return (
    <div className={styles.offer}>
    <div className={cn(styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          try it free for 7 days 🎾
        </div>
        <h2 className={cn("h1", styles.title)}>
          Anywhere You Want. Anytime You Want.
        </h2>
        <div className={styles.text}>
          Get started with 1 week free plan which consists of Customised
          Meal And Workout Plan. Cancel anytime.
        </div>
        <ScrollParallax className={styles.wrap}>
        <div className={styles.box}>
        <Link className={cn("button", styles.button)} to="/download">
          Start free trial
        </Link>
        </div>
        </ScrollParallax>
        </div>
    </div>
    </div>
  );
};

export default Offer;
