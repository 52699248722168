import React, { useState } from "react";
import cn from "classnames";
import styles from "./Team.module.sass";

// data
import { trainersList } from "../../../mocks/trainers";

const Team = () => {
  const options = [];
  trainersList.map((x) => options.push(x.title));

  const [direction, setDirection] = useState(options[0]);


  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>About Me</h2>
          <div className={styles.info}>
            Over 15 years of experience in Fitness,
            WBFF Australia Male Muscle Model Champion, Mr South India, Mr Bangalore,
            MuscleManiaIndia and Limca Book Of World Record Holder.
          </div>
          <div className={styles.nav}>
            {trainersList.map((x, index) => (
              <button
                className={cn(styles.btn, {
                  [styles.active]: x.title === direction,
                })}
                onClick={() => setDirection(x.title)}
                key={index}
              >
                {x.title}
                <br></br>
              </button>
            ))}
            
          </div>
          
        </div>
        
        <div className={styles.wrap}>
         
        </div>
      </div>
    </div>
  );
};

export default Team;
